import React, { Fragment } from 'react'
import { Global } from '@emotion/core'
import { Tabs, TabLink, TabContent } from 'react-tabs-redux'
import { Link } from 'gatsby'
import SEO from "../components/seo"
import NavBar from '../components/NavBar'
import Programacao from '../components/ProgramacaoHome'
import Footer from '../components/Footer'
import { globalStyles } from '../components/layout.styles'

import {
  inspired,
  container,
  title,
  videoPlay
} from '../components/styles/AoVivo.styles'

const SalaEnem = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Aulão Brasil - Os melhores professores, das melhores escolas do Brasil." />
    <NavBar location={props.location} />
    <div css={inspired} >
      <div css={container} style={{ paddingBottom: 80 }}>
        <div css={title}>Escolha a sala de seu interesse:</div>
        <Tabs
          onChange={tab => console.log(`Tab selected: ${tab}`)} // eslint-disable-line no-console
        >
          <div style={{ marginBottom: 36 }}>
            <TabLink to="tab1">Sala ENEM</TabLink>
            <Link
              to='/sala-ime-ita'
            >
              <TabLink to="tab3">Sala IME/ITA</TabLink>
            </Link>
          </div>
          <div style={{ paddingBottom: 100 }}>
            <TabContent for="tab1">
              <div css={videoPlay}>
                <div><iframe title='Sala ENEM' src="https://vimeo.com/event/52981/embed" width='800' height='500' frameborder="0" allow="autoplay; fullscreen" allowfullscreen /></div>
                <div><iframe title='Chat Sala ENEM' src="https://vimeo.com/event/52981/chat/" width="300" height="500" frameborder="0"></iframe></div>
              </div>
            </TabContent>
          </div>
        </Tabs>
      </div>
    </div>
    <Programacao />
    <div style={{ marginTop: 80 }} />
    <Footer />
  </Fragment>
)

export default SalaEnem
